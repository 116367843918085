import { Link } from 'gatsby';
import React from 'react';
import { Column, Container, Row } from '../../components/Grid';
import Icon from '../../components/Icon';
import Image from '../../components/Image';
import Layout from '../../components/Layout';
import OutboundLink from '../../components/OutboundLink';
import Section from '../../components/Section';

const RecentNews = () => (
  <Layout title="Recent News" className="news-and-events">
    <div className="hero-container">
      <div className="hero-container__gradient bg-gradient-light-blue"></div>
      <div className="hero-container__titleImage">
        {/* Breadcrumbs */}
        <ul className="breadcrumbs">
          <li className="breadcrumb">
            <Link to="../../news-and-events/recent-news">News</Link>
          </li>
          <li className="breadcrumb active">Recent News</li>
        </ul>
        <h1 className="page-title">Recent News</h1>
        <Image filename="hero-news-and-events.png" className="hero-image" />
      </div>
    </div>
    {/* Recent News Intro */}
    <Section>
      <Container>
        {/* Recent News Intro */}
        <Row>
          <Column size={8} offset={2} className="global-intro">
            <h2 id="news-intro">Find out what’s new in the world of science</h2>
            <h4>
              Stay connected to all the latest STEM news, important challenge
              updates and much more. Browse regularly updated content like blog
              entries, challenge exclusives, and thought starters, along with
              insights from today’s top young scientists.
            </h4>
          </Column>
        </Row>
      </Container>
    </Section>
    {/* Recent News Main Content */}
    <Section>
      <Container>
        <Row>
          {/* Recent News Left Column */}
          <Column size={9}>
            <ul className="filter-tabs news-and-events">
              {/* This will be turned back on AFTER site launch */}
              {/*<li className="filter-tabs__tab selected">
                <Link to="#news-intro">2022</Link>
              </li>*/}
              <li className="filter-tabs__tab selected">
                <Link to="../recent-news#news-intro">Program Updates</Link>
              </li>
              <li className="filter-tabs__tab">
                <Link to="/news-and-events/ysc-blog">Young Scientist Blog</Link>
              </li>
              {/* This will be turned back on AFTER site launch */}
              {/* <li className="filter-tabs__tab">
                <Link to="../alumni-news#news-intro">Alumni</Link>
              </li> */}
            </ul>
            {/* Begin News Listing */}
            <div className="news-listing">
              <ul>
                {/* Post */}
                <li className="news-listing__post">
                  <OutboundLink
                    to="https://time.com/7010714/time-and-time-for-kids-name-the-2024-kid-of-the-year-15-year-old-scientist-heman-bekele/"
                    className="news__outbound"
                  >
                    <Icon name="externallink" />
                    <h4 className="news-listing__date">
                      August 15, 2024 - <span>Time</span>
                    </h4>
                    <h2 className="news-listing__title">
                    Heman Bekele Is TIME's 2024 Kid of the Year
                    </h2>
                  </OutboundLink>
                </li>
                {/* Post */}
                <li className="news-listing__post">
                  <OutboundLink
                    to="https://www.forbes.com/sites/janinemaclachlan/2022/04/05/this-is-the-key-to-breakthrough-innovation-according-to-3m-scientist/?sh=5a154fc97e4b"
                    className="news__outbound"
                  >
                    <Icon name="externallink" />
                    <h4 className="news-listing__date">
                      April 13, 2022 - <span>Forbes</span>
                    </h4>
                    <h2 className="news-listing__title">
                      This Is The Key To Breakthrough Innovation, According To
                      3M Scientist
                    </h2>
                  </OutboundLink>
                </li>
                {/* Post */}
                <li className="news-listing__post">
                  <OutboundLink
                    to="https://patch.com/georgia/smyrna/s/hwlqk/marietta-teen-places-2nd-national-young-scientist-competition"
                    className="news__outbound"
                  >
                    <Icon name="externallink" />
                    <h4 className="news-listing__date">
                      November 2, 2021 - <span>Patch</span>
                    </h4>
                    <h2 className="news-listing__title">
                      Marietta Teen Places 2nd In National Young Scientist
                      Competition
                    </h2>
                  </OutboundLink>
                </li>
                {/* Post */}
                <li className="news-listing__post">
                  <OutboundLink
                    to="https://thejournal.com/articles/2021/10/28/2021-young-scientist-challenge-winners-named.aspx"
                    className="news__outbound"
                  >
                    <Icon name="externallink" />
                    <h4 className="news-listing__date">
                      October 28, 2021 - <span>THE Journal</span>
                    </h4>
                    <h2 className="news-listing__title">
                      2021 Young Scientist Challenge Winners Named
                    </h2>
                  </OutboundLink>
                </li>
                {/* Post */}
                <li className="news-listing__post">
                  <OutboundLink
                    to="https://community.thriveglobal.com/are-you-americas-next-top-young-scientist/"
                    className="news__outbound"
                  >
                    <Icon name="externallink" />
                    <h4 className="news-listing__date">
                      October 15, 2021 - <span>Thrive Global</span>
                    </h4>
                    <h2 className="news-listing__title">
                      Are You America’s Next Top Young Scientist? 3M and
                      Discovery Education Think You Just Might Be!
                    </h2>
                  </OutboundLink>
                </li>
                {/* Post */}
                <li className="news-listing__post">
                  <OutboundLink
                    to="https://medium.com/authority-magazine/young-change-makers-why-and-how-anika-chebrolu-is-helping-to-change-our-world-7022f4e87cdb"
                    className="news__outbound"
                  >
                    <Icon name="externallink" />
                    <h4 className="news-listing__date">
                      September 6, 2021 - <span>Authority Magazine</span>
                    </h4>
                    <h2 className="news-listing__title">
                      Young Change Makers: Why and How Anika Chebrolu Is Helping
                      To Change Our World
                    </h2>
                  </OutboundLink>
                </li>
                {/* Post */}
                <li className="news-listing__post">
                  <OutboundLink
                    to="https://districtadministration.com/using-project-based-learning-during-remote-instruction/"
                    className="news__outbound"
                  >
                    <Icon name="externallink" />
                    <h4 className="news-listing__date">
                      August 12, 2021 - <span>District Administration</span>
                    </h4>
                    <h2 className="news-listing__title">
                      Using project-based learning during remote instruction
                    </h2>
                  </OutboundLink>
                </li>
                {/* Post */}
                <li className="news-listing__post">
                  <OutboundLink
                    to="https://www.tysonsreporter.com/2021/07/08/great-falls-seventh-grader-wins-prize-for-electric-vehicle-charging-science-experiment/"
                    className="news__outbound"
                  >
                    <Icon name="externallink" />
                    <h4 className="news-listing__date">
                      July 8, 2021 - <span>Tysons Reporter</span>
                    </h4>
                    <h2 className="news-listing__title">
                      Great Falls seventh-grader wins prize for electric vehicle
                      charging science experiment
                    </h2>
                  </OutboundLink>
                </li>
                {/* Post */}
                <li className="news-listing__post">
                  <OutboundLink
                    to="https://wgntv.com/news/wgn-news-now/naperville-student-competes-in-3m-young-scientist-challenge/"
                    className="news__outbound"
                  >
                    <Icon name="externallink" />
                    <h4 className="news-listing__date">
                      July 7, 2021 - <span>Chicago WGN9</span>
                    </h4>
                    <h2 className="news-listing__title">
                      Naperville student competes in 3M Young Scientist
                      Challenge
                    </h2>
                  </OutboundLink>
                </li>
                {/* Post */}
                <li className="news-listing__post">
                  <OutboundLink
                    to="https://www.chicagotribune.com/suburbs/naperville-sun/ct-nvs-naperville-people-st-0709-20210707-mh3c3q43y5anbmfqdxeoyes3ay-story.html"
                    className="news__outbound"
                  >
                    <Icon name="externallink" />
                    <h4 className="news-listing__date">
                      July 7, 2021 - <span>Chicago Tribune</span>
                    </h4>
                    <h2 className="news-listing__title">
                      Bezaire Recipient of 3M Scholarship
                    </h2>
                  </OutboundLink>
                </li>
                {/* Post */}
                <li className="news-listing__post">
                  <OutboundLink
                    to="https://gulfnews.com/friday/art-people/gitanjali-rao-times-kid-of-the-year-is-using-ai-to-fight-cyberbullying-1.2312414"
                    className="news__outbound"
                  >
                    <Icon name="externallink" />
                    <h4 className="news-listing__date">
                      July 1, 2021 - <span>Friday</span>
                    </h4>
                    <h2 className="news-listing__title">
                      Gitanjali Rao, Time’s Kid of the Year, is using AI to
                      fight cyberbullying
                    </h2>
                  </OutboundLink>
                </li>
                {/* Post */}
                <li className="news-listing__post">
                  <OutboundLink
                    to="https://theatlantavoice.com/marietta-teen-finalist-in-top-young-scientist-challenge/"
                    className="news__outbound"
                  >
                    <Icon name="externallink" />
                    <h4 className="news-listing__date">
                      June 29, 2021 - <span>The Atlanta</span>
                    </h4>
                    <h2 className="news-listing__title">
                      Voice Marietta Teen finalist in 3M Young Scientist
                      Challenge
                    </h2>
                  </OutboundLink>
                </li>
                {/* Post */}
                <li className="news-listing__post">
                  <OutboundLink
                    to="https://times-journal.com/news/article_1ccb42fc-d908-11eb-939f-83c325ab3918.html"
                    className="news__outbound"
                  >
                    <Icon name="externallink" />
                    <h4 className="news-listing__date">
                      June 29, 2021 - <span>Times Journal</span>
                    </h4>
                    <h2 className="news-listing__title">
                      Fort Payne student named state winner in nation’s premier
                      middle school science competition
                    </h2>
                  </OutboundLink>
                </li>
                {/* Post */}
                <li className="news-listing__post">
                  <OutboundLink
                    to="https://www.globalcitizen.org/en/content/time-kid-of-the-year-2020/"
                    className="news__outbound"
                  >
                    <Icon name="externallink" />
                    <h4 className="news-listing__date">
                      December 3, 2020 - <span>Global Citizen</span>
                    </h4>
                    <h2 className="news-listing__title">
                      2017 3M YSC Winner Gitanjali Rao: Named TIME’s First-Ever
                      ’Kid of the Year'
                    </h2>
                  </OutboundLink>
                </li>
                {/* Post */}
                <li className="news-listing__post">
                  <OutboundLink
                    to="https://www.forbes.com/sites/natalieparletta/2019/07/08/children-rising-meet-the-earths-future-custodians/?sh=254c8bf46a4e"
                    className="news__outbound"
                  >
                    <Icon name="externallink" />
                    <h4 className="news-listing__date">
                      July 11, 2019 - <span>Forbes</span>
                    </h4>
                    <h2 className="news-listing__title">
                      Children Rising: Meet The Earth's Future Custodians
                    </h2>
                  </OutboundLink>
                </li>
                {/* Post */}
                <li className="news-listing__post">
                  <OutboundLink
                    to="https://www.fastcompany.com/90371320/these-10-middle-schoolers-are-inventing-new-ways-to-change-the-world"
                    className="news__outbound"
                  >
                    <Icon name="externallink" />
                    <h4 className="news-listing__date">
                      July 11, 2019 - <span>Fast Company</span>
                    </h4>
                    <h2 className="news-listing__title">
                      These 10 middle-schoolers are inventing new ways to change
                      the world
                    </h2>
                  </OutboundLink>
                </li>
                {/* Post */}
                <li className="news-listing__post">
                  <OutboundLink
                    to="https://www.brainson.org/episode/2018/05/01/whats-in-your-water"
                    className="news__outbound"
                  >
                    <Icon name="externallink" />
                    <h4 className="news-listing__date">
                      May 1, 2018 - <span>Brains On Podcast</span>
                    </h4>
                    <h2 className="news-listing__title">
                      MN Public Radio What's in your water?
                    </h2>
                  </OutboundLink>
                </li>
                {/* Post */}
                <li className="news-listing__post">
                  <OutboundLink
                    to="https://www.washingtonpost.com/lifestyle/kidspost/spring-into-action-for-these-contests/2018/03/16/4ef86a9c-2287-11e8-badd-7c9f29a55815_story.html"
                    className="news__outbound"
                  >
                    <Icon name="externallink" />
                    <h4 className="news-listing__date">
                      March 18, 2018 - <span>The Washington Post</span>
                    </h4>
                    <h2 className="news-listing__title">
                      Spring into action for these contests
                    </h2>
                  </OutboundLink>
                </li>
                {/* Post */}
                <li className="news-listing__post">
                  <OutboundLink to="" className="news__outbound">
                    <Icon name="externallink" />
                    <h4 className="news-listing__date">
                      March 13, 2018 - <span>Brandpoint</span>
                    </h4>
                    <h2 className="news-listing__title">
                      Stemming the leak: 4 ways to encourage stem in your middle
                      schooler
                    </h2>
                  </OutboundLink>
                </li>
              </ul>
            </div>
          </Column>
          {/* Press Releases Right Column */}
          <Column size={3} className="mt-2">
            <div className="right__column-links">
              <h3>Press Releases</h3>
              <ul className="news__cta-links">
                <li>
                  <OutboundLink to="https://www.discoveryeducation.com/details/14-year-old-named-americas-top-young-scientist-for-inventing-an-ai-handheld-pesticide-detector/">
                    <Icon name="externallink" />
                    <h4>October 16th, 2024</h4>
                    <span className="news__cta-title">
                      14-year-old named America's Top Young Scientist for inventing an AI handheld pesticide detector
                    </span>
                  </OutboundLink>
                </li>
                <li>
                  <OutboundLink to="https://www.discoveryeducation.com/details/3m-young-scientist-challenge-announces-2024-national-finalists/">
                    <Icon name="externallink" />
                    <h4>June 27, 2024</h4>
                    <span className="news__cta-title">
                      3M Young Scientist Challenge Announces 2024 National
                      Finalists
                    </span>
                  </OutboundLink>
                </li>
                <li>
                  <OutboundLink to="https://www.discoveryeducation.com/details/3m-and-discovery-education-recognize-26-state-merit-winners-and-four-honorable-mentions-in-2024-3m-young-scientist-challenge/">
                    <Icon name="externallink" />
                    <h4>June 26, 2024</h4>
                    <span className="news__cta-title">
                      3M and Discovery Education Recognize 26 State Merit
                      Winners and Four Honorable Mentions in 2024 3M Young
                      Scientist Challenge
                    </span>
                  </OutboundLink>
                </li>
                <li>
                  <OutboundLink to="https://www.discoveryeducation.com/details/search-begins-for-2024-americas-top-young-scientist/">
                    <Icon name="externallink" />
                    <h4>January 16, 2024</h4>
                    <span className="news__cta-title">
                      Search Begins for 2024 America’s Top Young Scientist
                    </span>
                  </OutboundLink>
                </li>
                <li>
                  <OutboundLink to="https://www.discoveryeducation.com/details/14-year-old-named-americas-top-young-scientist-for-development-of-skin-cancer-treatment/">
                    <Icon name="externallink" />
                    <h4>October 11th, 2023</h4>
                    <span className="news__cta-title">
                      14-year-old named America's Top Young Scientist for development of skin cancer treatment
                    </span>
                  </OutboundLink>
                </li>
                <li>
                  <OutboundLink to="https://www.discoveryeducation.com/details/3m-young-scientist-challenge-announces-2023-national-finalists/">
                    <Icon name="externallink" />
                    <h4>June 27, 2023</h4>
                    <span className="news__cta-title">
                      3M Young Scientist Challenge Announces 2023 National
                      Finalists
                    </span>
                  </OutboundLink>
                </li>
                <li>
                  <OutboundLink to="https://www.discoveryeducation.com/details/3m-and-discovery-education-recognize-24-state-merit-winners-and-4-honorable-mentions-in-2023-3m-young-scientist-challenge/">
                    <Icon name="externallink" />
                    <h4>June 26, 2023</h4>
                    <span className="news__cta-title">
                      3M and Discovery Education Recognize 24 State Merit
                      Winners and 4 Honorable Mentions in 2023 3M Young
                      Scientist Challenge
                    </span>
                  </OutboundLink>
                </li>
                <li>
                  <OutboundLink to="https://www.discoveryeducation.com/details/3m-and-discovery-education-name-10-grant-recipients-supporting-nations-young-scientists/">
                    <Icon name="externallink" />
                    <h4>May 24, 2023</h4>
                    <span className="news__cta-title">
                      3M and Discovery Education Name 10 Grant Recipients
                      Supporting Nation's Young Scientists
                    </span>
                  </OutboundLink>
                </li>
                <li>
                  <OutboundLink
                    to="https://www.discoveryeducation.com/details/14-year-old-from-california-named-americas-top-young-scientist/"
                    className="news__outbound"
                  >
                    <Icon name="externallink" />
                    <h4>October 19th, 2022</h4>
                    <span className="news__cta-title">
                      14-Year-old from California Named America's Top Young Scientist
                    </span>
                  </OutboundLink>
                </li>
                <li>
                  <OutboundLink
                    to="https://www.discoveryeducation.com/details/3m-young-scientist-challenge-announces-2022-national-finalists-and-honorable-mentions/"
                    className="news__outbound"
                  >
                    <Icon name="externallink" />
                    <h4>July 7th, 2022</h4>
                    <span className="news__cta-title">
                      3M Young Scientist Challenge Announces 2022 National
                      Finalists and Honorable Mentions
                    </span>
                  </OutboundLink>
                </li>
                <li>
                  <OutboundLink
                    to="https://www.discoveryeducation.com/details/3m-and-discovery-education-recognize-31-state-merit-winners-in-2022-3m-young-scientist-challenge/"
                    className="news__outbound"
                  >
                    <Icon name="externallink" />
                    <h4>June 27th, 2022</h4>
                    <span className="news__cta-title">
                      3M and Discovery Education Recognize 31 State Merit
                      Winners in 2022 3M Young Scientist Challenge
                    </span>
                  </OutboundLink>
                </li>
                <li>
                  <OutboundLink
                    to="https://www.discoveryeducation.com/details/3m-names-americas-top-young-scientist-of-2021-14-year-old-sarah-park-for-music-therapy-treatment-to-improve-mental-health/"
                    className="news__outbound"
                  >
                    <Icon name="externallink" />
                    <h4>October 20th, 2021</h4>
                    <span className="news__cta-title">
                      3M Names America's Top Young Scientist of 2021: 14-Year-Old Sarah Park, for Music Therapy Treatment to Improve Mental Health
                    </span>
                  </OutboundLink>
                </li>
                <li>
                  <OutboundLink
                    to="https://www.discoveryeducation.com/details/3m-young-scientist-challenge-announces-2021-national-finalists-and-honorable-mentions/"
                    className="news__outbound"
                  >
                    <Icon name="externallink" />
                    <h4>June 24th, 2021</h4>
                    <span className="news__cta-title">
                      3M Young Scientist Challenge Announces 2021 National
                      Finalists and Honorable Mentions
                    </span>
                  </OutboundLink>
                </li>
                <li>
                  <OutboundLink
                    to="https://www.discoveryeducation.com/details/3m-and-discovery-education-announce-state-merit-winners-in-the-2021-3m-young-scientist-challenge/"
                    className="news__outbound"
                  >
                    <Icon name="externallink" />
                    <h4>June 23rd, 2021</h4>
                    <span className="news__cta-title">
                      3M and Discovery Education Announce State Merit Winners in
                      the 2021 3M Young Scientist Challenge
                    </span>
                  </OutboundLink>
                </li>
                <li>
                  <OutboundLink
                    to="https://www.discoveryeducation.com/details/americas-top-young-scientist-of-2020-14-year-old-researches-spike-protein-of-sars-cov-2-virus-to-develop-novel-antiviral-drug-to-combat-spread-of-covid-19/"
                    className="news__outbound"
                  >
                    <Icon name="externallink" />
                    <h4>October 14th, 2020</h4>
                    <span className="news__cta-title">
                      America’s Top Young Scientist of 2020: 14-Year-Old
                      Researches Spike Protein of SARS-CoV-2 Virus to Develop
                      Novel Antiviral Drug to Combat Spread of COVID-19
                    </span>
                  </OutboundLink>
                </li>
              </ul>
            </div>
          </Column>
        </Row>
      </Container>
    </Section>
  </Layout>
);

export default RecentNews;
